// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/Users/marcindyrda/dev/kopax-lp/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-budownictwo-mieszkaniowe-js": () => import("/Users/marcindyrda/dev/kopax-lp/src/pages/budownictwo-mieszkaniowe.js" /* webpackChunkName: "component---src-pages-budownictwo-mieszkaniowe-js" */),
  "component---src-pages-budownictwo-przemyslowe-js": () => import("/Users/marcindyrda/dev/kopax-lp/src/pages/budownictwo-przemyslowe.js" /* webpackChunkName: "component---src-pages-budownictwo-przemyslowe-js" */),
  "component---src-pages-budynek-na-sprzedaz-js": () => import("/Users/marcindyrda/dev/kopax-lp/src/pages/budynek-na-sprzedaz.js" /* webpackChunkName: "component---src-pages-budynek-na-sprzedaz-js" */),
  "component---src-pages-index-js": () => import("/Users/marcindyrda/dev/kopax-lp/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-obiekt-na-wynajem-js": () => import("/Users/marcindyrda/dev/kopax-lp/src/pages/obiekt-na-wynajem.js" /* webpackChunkName: "component---src-pages-obiekt-na-wynajem-js" */),
  "component---src-pages-remonty-oraz-renowacje-js": () => import("/Users/marcindyrda/dev/kopax-lp/src/pages/remonty-oraz-renowacje.js" /* webpackChunkName: "component---src-pages-remonty-oraz-renowacje-js" */),
  "component---src-pages-test-js": () => import("/Users/marcindyrda/dev/kopax-lp/src/pages/Test.js" /* webpackChunkName: "component---src-pages-test-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/marcindyrda/dev/kopax-lp/.cache/data.json")

